import { Stack, Title } from '@mantine/core';
import { Announcements } from '~/components/Announcements/Announcements';
import { ImageCategories } from '~/components/Image/Filters/ImageCategories';
import { useImageQueryParams } from '~/components/Image/image.utils';
import ImagesInfinite from '~/components/Image/Infinite/ImagesInfinite';
import { IsClient } from '~/components/IsClient/IsClient';
import { MasonryProvider } from '~/components/MasonryColumns/MasonryProvider';
import { Meta } from '~/components/Meta/Meta';
import { ToolBanner } from '~/components/Tool/ToolBanner';
import { env } from '~/env/client.mjs';
import { constants } from '~/server/common/constants';

const maxColumnCount = 7;

export default function Home() {
  const { query } = useImageQueryParams();
  const { hidden } = query;
  // const { data: homeBlocks = [], isLoading } = trpc.homeBlock.getHomeBlocks.useQuery();
  // const { data: homeExcludedTags = [], isLoading: isLoadingExcludedTags } =
  //   trpc.tag.getHomeExcluded.useQuery(undefined, { trpc: { context: { skipBatch: true } } });

  return (
    <>
      <Meta
        title="SDCloud | AI-Generate Art Showcase"
        description="See the latest art created by the generative AI art community and delve into the inspirations and prompts behind their work"
        links={[{ href: `${env.NEXT_PUBLIC_BASE_URL}/`, rel: 'canonical' }]}
      />
      <ToolBanner />
      {/* <MasonryProvider
        columnWidth={constants.cardSizes.image}
        maxColumnCount={maxColumnCount}
        maxSingleColumnWidth={450}
        style={{ margin: 0, flex: 1, zIndex: 10 }}
        pb="md"
      >
        <Announcements />
        {hidden && <Title>Your Hidden Images</Title>}
        <Stack spacing="xs">
          <IsClient>
            <ImageCategories />
            <ImagesInfinite showEof showAds useIndex />
          </IsClient>
        </Stack>
      </MasonryProvider> */}
    </>
  );
}
